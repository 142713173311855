import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmail } from 'validator';
import useWindowDimensions from "../../Hooks/useWindowDimensions";

import { event } from "react-ga";
import MiscContext from "../../Store/MiscContext";

const Invite = () => {

  const miscCtx = useContext(MiscContext);
  const { windowWidth } = useWindowDimensions();
  let navigate = useNavigate();
  const { id } = useParams();

  const [isSaving, setIsSaving] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // const [list, setList]                     = useState([]);
  const [invitees, setInvitees] = useState([]);
  const [firstRowName, setFirstRowName] = useState("");
  const [firstRowEmail, setFirstRowEmail] = useState("");
  const [checkBoxIp, setcheckBoxIp] = useState();

  useEffect(() => {
    let invitee = [{
      id: 1,
      name: "",
      email: "",
      showFullName: false,
      showEmail: false
    }]
    setInvitees(invitee);
    // document.body.style.backgroundColor = "#292829";
  }, []);

  // fullNameRegex allows letters, diacritics, spaces, single quotes, hyphens. needs one space so requires at least 2 words
  const fullNameRegex = /^([A-Za-zÀ-ÖØ-öø-ÿ'-]+ +)+[A-Za-zÀ-ÖØ-öø-ÿ'-]+$/;

  const disableInviteBtn = () => {
    setIsDisabled(false);
    invitees.forEach(invitee => {
      if (!fullNameRegex.test(invitee?.name?.trim()) || !isEmail(invitee?.email?.trim())) {
        setIsDisabled(true);
      }
    });
  }

  const checkFormValid = () => {
    if (invitees.length > 0) {
      let valid = true;
      invitees.forEach(invitee => {
        if (!fullNameRegex.test(invitee?.name?.trim()) || !isEmail(invitee?.email?.trim())) {
          valid = false;
        }
      });
      setIsFormValid(valid);
    }
  }

  const submitToApi = (member) => {

    miscCtx.setIsInviteSuccessful(false);
    setIsSaving(true);
    const payload = {
      members: member
    }

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/share/' + id, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // navigate("/invite-team-thanks/" + id, { replace: true });
        miscCtx.setIsInviteSuccessful(true);
      } else {
        setIsSaving(false);
        miscCtx.setIsInviteSuccessful(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      setIsSaving(false);
      miscCtx.setIsInviteSuccessful(false);
      console.error("Failed to send invites...", err);
    })

  }


  const addBtnDisabled = () => {

    let isAddBtnDisabled = false;
    invitees.forEach(invitee => {
      if (!invitee?.name?.length) {
        isAddBtnDisabled = true;
      }
      if (!invitee?.email.length) {
        isAddBtnDisabled = true;
      }
    });

    return isAddBtnDisabled
  }

  // console.log("invitees", invitees);

  const handleSubmit = () => {

    setIsSaving(true);

    let formHasError = false;
    invitees.forEach(invitee => {
      if (!fullNameRegex.test(invitee.name?.trim())) {
        invitee.showFullName = true;
        formHasError = true;
      }
      if (!isEmail(invitee.email?.trim())) {
        invitee.showEmail = true;
        formHasError = true;
      }
    });

    if (formHasError) {
      setTimeout(() => { setIsSaving(false); }, 500);
    } else if (!formHasError && isFormValid) {
      submitToApi(invitees);
    }

  }

  const deletePerson = (p) => {
    setInvitees(invitees.filter((person) => {
      return person.id !== p
    }));
  };

  const addPerson = () => {
    let formHasError = false;
  
    const updatedInvitees = invitees.map(invitee => {
      if (!fullNameRegex.test(invitee.name?.trim())) {
        invitee.showFullName = true;
        formHasError = true;
      }
      if (!isEmail(invitee.email?.trim())) {
        invitee.showEmail = true;
        formHasError = true;
      }
      return invitee;
    });
  
    setInvitees(updatedInvitees);
  
    if (!formHasError) {
      const newId = invitees.length > 0 ? invitees[invitees.length - 1].id + 1 : 1;
  
      const newPerson = {
        id: newId,
        name: "",
        email: "",
        showFullName: false,
        showEmail: false,
      };
  
      setInvitees([...invitees, newPerson]);
    }
  };

  const updateName = (e, id) => {
    let currentMember = invitees.find(function (obj) { return obj.id == id; });
    currentMember.name = e.target.value;
    disableInviteBtn();
    checkFormValid();
  }

  const updateEmail = (e, id) => {
    let currentMember = invitees.find(function (obj) { return obj.id == id; });
    currentMember.email = e.target.value;
    disableInviteBtn();
    checkFormValid();
  }

  const showButton = () => {
    if (isSaving && isFormValid) {
      // return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
      return "Sending Invite...";

    }
    return "Send Invite";
  }

  const removeError = (id, field, e) => {

    // change active input field title color 
    if (e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#555555")
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#555555";
    }

    let newinvitees = [];
    invitees.forEach(invitee => {
      if (field == "fullName" && invitee.id == id) {
        invitee.showFullName = false;
      }
      if (field == "email" && invitee.id == id) {
        invitee.showEmail = false;
      }
      newinvitees.push(invitee);
    });
    setInvitees(newinvitees);
  }

  const handleCheckBoxIp = (e) => {
    setcheckBoxIp(e.target.checked)
  }


  return (
    <>
      <div className="container invite">
        <div className="details-container">
          <h1>Get a comprehensive outline of your ministry toolkit</h1>
          <p>Confirm the information of the team members you’d like us to instantly invite on your behalf to share their input.</p>
          {/* <p>Their results will populate your dashboard and as the Head of School, you'll see how the entire leadership team of the Board + Head of School aligns individually and collectively.</p> */}

          {
            invitees.map((data, index) => (
              <>
                <div className="form _form" key={data.id}>
                  <div className="invite-number">{index + 1}.</div>
                  <div className="form-detail">
                    <p className="input-title">Full name</p>
                    <TextField hiddenLabel placeholder="E.g. John Zebedee" onFocus={(e) => { removeError(data.id, "fullName", e) }} onChange={(e) => { updateName(e, data.id) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" />
                    {
                      data.showFullName ?
                        <div className="error-text">Please enter their full name.</div>
                        : null
                    }
                  </div>
                  <div className="form-detail">
                    <p className="input-title">Email</p>
                    <TextField hiddenLabel placeholder="E.g. johnz@petra.church" onFocus={(e) => { removeError(data.id, "email", e) }} onChange={(e) => { updateEmail(e, data.id) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" type="email" />
                    {
                      data.showEmail ?
                        <div className="error-text">Please enter their valid email ID.</div>
                        : null
                    }
                  </div>
                  {data.id != 1 ?
                    <div className="delete-btn">
                      <div onClick={() => deletePerson(data.id)}>
                        <DeleteIcon className="delete-icon" />
                      </div>
                    </div>
                    : null
                  }
                </div>
                <hr />
              </>
            ))
          }

          <div className="invite-another-person">
            <div className="invite-another">

              <div className="form_icons--icon">
                <Button onClick={addPerson} className="add-btn" >
                  <AddIcon className="invite-another-icon" />Add&nbsp;
                  {/* + Add&nbsp; */}
                </Button>
              </div>
            </div>
          </div>

          <div className="buttons">
            {windowWidth <= 830 && <Button onClick={addPerson} className="add-btn" >
              <AddIcon className="invite-another-icon" />Add&nbsp;
            </Button>}
            <Button style={isSaving ? { opacity: "0.7" } : { opacity: "1" }} className="cta-btn" disabled={isDisabled} onClick={() => handleSubmit()}>{showButton()}</Button>
          </div>
          {/* <div className="include-results-confirmation">
            <input type="checkbox" onChange={handleCheckBoxIp} />
            <h4>Include my results in the email.</h4>
            <label htmlFor="includeResultsCheckbox">
            <input type="checkbox" id="includeResultsCheckbox" onChange={handleCheckBoxIp} />
              Include my results in the email.
            </label>
          </div> */}
        </div>
      </div>
    </>
  );

};

export default Invite;

