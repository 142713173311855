import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, Button, CircularProgress, LinearProgress, Snackbar, Alert } from '@mui/material';

import Question from '../../Components/Questions/Questions.jsx';
import ProgressBar from '../../Components/ProgressBar/ProgressBar.jsx';
import ProgressBarSingle from '../../Components/ProgressBarSingle/ProgressBarSingle.jsx';

// import prevArrow from '../../Assets/Images/prev-arrow.svg';
import prevArrow from '../../Assets/Images/prevArrow.svg';
import nextArrow from '../../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../../Assets/Images/next-arrow-white.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import AssessmentQuestionContext from '../../Store/AssessmentQuestionContext';
import QuestionsProgressContext from '../../Store/QuestionsProgressContext';
import QuestionInfo from '../../Components/QuestionInfo/QuestionInfo.jsx';
import { questions_statistics } from '../../temp.js';
import AssessmentTypeContext from '../../Store/AssessmentTypeContext.jsx';


const AssessmentV3 = () => {

  // const assessProgressCtx = useContext(AssessmentProgressContext);
  // console.log("assessProgressCtx", assessProgressCtx);
  const assessCtx = useContext(AssessmentQuestionContext);
  const assessmentTypeCtx = useContext(AssessmentTypeContext)
  const questionsCtx = useContext(QuestionsProgressContext);

  const { windowWidth } = useWindowDimensions();
  let navigate = useNavigate();
  const { id, pageId } = useParams();

  console.log("pageId", pageId);

  const questionsWithoutStats = [];

  // useEffect(() => {
  //   getQuestions();
  // }, [pageId]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [nextValue, setNextValue] = useState(0);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [currentHash, setCurrentHash] = useState(0);
  const [hasAdditional, setHasAdditional] = useState(false);
  const [qs, setQs] = useState([]);
  const [apiData, setApiData] = useState();
  const [oldQuestions, setOldQuestions] = useState();
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [prevBtnClicked, setPrevBtnClicked] = useState(false);
  const [showStatistics, setShowStatistics] = useState(false);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [answerSubmissionError, setAnswerSubmissionError] = useState(false);
  const [onLastStatisticsPage, setOnLastStatisticsPage] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [queSnackbarOpen, setQueSnackbarOpen] = useState(false);
  const [showButton, setShowButton] = useState(false)
  const [currentStatisticData, setCurrentStatisticData] = useState([])
  const questionType = questions?.[activeStep]?.answerType;
  const [showOtherOption, setShowOtherOption] = useState(true)
  const [isNextBtnEnabled, setIsNextBtnEnabled] = useState(false);
  const [textAns, setTextAns] = useState('');


  const percentagesArray = [];

  // console.log("searchParams.get('member')", searchParams.get("member"));

  useEffect(() => {
    const delayNextButton = () => {
      if (!checkNextBtnDisabled()) {
        setTimeout(() => {
          setIsNextBtnEnabled(true);
        }, 400);
      } else {
        setIsNextBtnEnabled(false);
      }
    };
    delayNextButton();
  }, [activeStep, questions, showStatistics]);

  useEffect(() => {
    // to hide Next button when user has just answered the question (500ms delay) and 
    // to show it again when user clicks on Prev button

    let timeoutId = null;
    let conditionToShowNextBtn = (
      (questions?.[activeStep]?.answerType !== "slider" &&
        questions?.[activeStep]?.answerType !== "multiple_choice" &&
        questions?.[activeStep]?.answerType !== "select" &&
        questions?.[activeStep]?.answerType !== "month_year") ||
      (questions?.[activeStep]?.answer >= 0) || questions?.[activeStep]?.answerTxt?.length > 0);

    if (conditionToShowNextBtn && !prevBtnClicked) {
      timeoutId = setTimeout(() => {
        setShowNextBtn(true);
      }, 500);
    }

    if (conditionToShowNextBtn && prevBtnClicked) {
      setPrevBtnClicked(false);
      setShowNextBtn(true);
    }

    if (!conditionToShowNextBtn) {
      setShowNextBtn(false);
    }

    if (showStatistics) {
      setShowNextBtn(true);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [prevBtnClicked, activeStep, questions, showStatistics]);

  useEffect(() => {
    // document.body.style.backgroundColor = "#E5E5E5";

    setIsLoading(true);
    getQuestions();
    localStorage.removeItem("backFromDetailsPage");
    getBenchMarkingAnswers()
    // setQs(questions);
    // if(id != null) {      
    //   getAssessment();
    // } else {
    //   getQuestions();
    // }

    // getOldQuestions();

    // assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
    //   if(sec.title === "Response") return { ...sec, status: "active" };
    //   else return { ...sec, status: "unfinished" };
    // }));

  }, [pageId]);

  useEffect(() => {
    if (localStorage.getItem("backFromAddQPage") === "true" && questions?.length) {
      // console.log("activeStep", activeStep); console.log("questions", questions);
      setActiveStep(questions?.length - 1);
      localStorage.removeItem("backFromAddQPage");
    }
  }, [questions, pageId]);

  // useEffect(() => {
  //   // if (questions?.[activeStep]?.orderNo === questions?.length) {
  //   if ( activeStep+1 === questions?.length) {
  //     setOnLastStatisticsPage(true);
  //   } else setOnLastStatisticsPage(false);
  // }, [questions, activeStep]);

  // useEffect(() => {
  //   if (questions?.[activeStep]?.orderNo === questions?.length) {
  //     setOnLastStatisticsPage(true);
  //   } else setOnLastStatisticsPage(false);
  // }, [questions, activeStep]);

  useEffect(() => {
    if (answerSubmissionError) {
      setSnackbarOpen(true);
    }
  }, [answerSubmissionError]);

  useEffect(() => {
    if (!questions?.[activeStep]?.submissionSuccess) {
      setQueSnackbarOpen(true);
    } else {
      setQueSnackbarOpen(false);
    }
  }, [activeStep, questions]);


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCloseQueSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setQueSnackbarOpen(false);
  };

  function getAssessment() {
    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
    // 	method: 'GET',
    // 	mode: 'cors',
    // 	headers: { 'Content-Type': 'application/json' }
    // }).then(response => {
    // 	return response.json();
    // }).then(data => {
    // 	if(data.status == "success") {
    // 		setSections(data.sections);
    // 		setQuestions(data.questions);
    //     setQuestionCount(data.questions?.length);
    //     if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);
    //     setIsLoading(false);
    //     setActiveStep(data.questions?.length - 1);
    //     setCurrentHash(id);
    // 	} else {
    // 		// setOpenSnackbar(true);
    // 	}
    // });
  }


  function getQuestions() {

    // if(assessCtx.questions?.length > 0) {
    //   console.log("assessCtx.questions", assessCtx.questions);
    //   setQuestions(assessCtx.questions);
    //   setQuestionCount(assessCtx.questions?.length);
    //   setIsLoading(false);

    //   // go to the last additional question if coming back from Details page 
    //   // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
    //   if(localStorage.getItem("backFromDetailsPage") === "true") {
    //     setActiveStep(assessCtx.questions?.length - 1);
    //   } else {
    //     // go to the first unanswered question 
    //     for (let i = 0; i < assessCtx.questions?.length; i++) {
    //       if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
    //         setActiveStep(i);
    //         break;
    //       } else setActiveStep(assessCtx.questions?.length - 1);
    //     }
    //   }
    // }
    // else {

    let clientId = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        console.log("getQuestionsData", data);
        let questions = data.data?.questions;
        if (retrieveStoredAnswers()) {
          let answeredQuestions = [];
          let storedQuestions = retrieveStoredAnswers();
          questions?.forEach(q => {
            let question = storedQuestions.find(obj => obj.id == q.id);
            if (question) {
              q.answerTxt = question.answerTxt;
              q.answer = question.answer;
              // q.isOther = question.isOther;
              // q.isOtherValue = question.isOtherValue;
              q.answers = question.answers;
            }
          })
        }
        // setSections(data.sections);
        // setQuestions(data.questions);
        // setQuestionCount(data.questions?.length);
        // assessCtx.addQuestions(questions);
        // if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);

        // go to first unanswered question 
        let goingForward = localStorage.getItem("goingForward") === "true" ? true : false;

        const jumpToUnansweredQuestion = () => {
          let sortedQuestions = data?.data.questions;
          sortedQuestions = sortedQuestions?.sort((a, b) => a.orderNo - b.orderNo);
          for (let i = 0; i < sortedQuestions?.length; i++) {
            if (sortedQuestions?.[i]?.answer == undefined && assessCtx.questions?.[i]?.answer !== 0) {
              setActiveStep(i);
              break;
            } else setActiveStep(sortedQuestions?.length - 1);
          }
        }

        if (goingForward) {
          setActiveStep(0);
          localStorage.removeItem("goingForward");
        }
        else jumpToUnansweredQuestion();

        setSections(data?.data.sections);
        const questionIdsToRemove = [1626, 1627, 1638, 1639, 1640, 1641, 1642, 1643, 1644];
        const filteredQuestions = data?.data.questions.filter(question => !questionIdsToRemove.includes(question.id));
        if (assessmentTypeCtx?.assessmentType === "Ministry") {
          setQuestions(data?.data.questions);
        }
        else {
          setQuestions(filteredQuestions);
        }
        assessCtx.addQuestions(questions);
        setQuestionCount(data?.data.questions?.length);
        console.log("QUESTIONS", data?.data.questions);
        console.log("QUESTIONS", data?.data);
        setApiData(data.data);
        setIsLoading(false);
      } else {
        console.error("Error while fetching questions...", data);
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error("Error while fetching questions...", err);
      setIsLoading(false);
    });
    // }
  }

  // useEffect(() => {
  //   setQuestions(apiData);
  // }, [apiData])

  console.log("apiData", apiData);

  const currentSectionName = sections?.find(section => section.id === questions?.[activeStep]?.sectionId)?.section;

  console.log("hasAdditional", hasAdditional);

  function completeAssessment() {

    setIsLoading(true);
    let clientId = process.env.REACT_APP_CLIENT_ID;

    let isReview = false;
    if (id != null) {
      isReview = true;
    }

    const jsonBody = { pageId: 894, questions: questions, isReview: isReview }

    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        const addQuestionsPageId = 836;
        const detailsPageId = 895;
        localStorage.removeItem("assessmentAnswers");
        // navigate(`/assessment-additional/${id}/${addQuestionsPageId}?${searchParams}`, { replace: true });
        navigate(`/details/${id}/${detailsPageId}?${searchParams}`, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        console.error("Failed to submit answers...", data);
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error("Failed to submit answers...", err);
      setIsLoading(false);
    });


    // console.log("questions", questions);
    // fetch(process.env.REACT_APP_API_URI + '/v3/questions/' + questionsCtx.currentPage, {
    //   method: 'POST', mode: 'cors',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ clientId, questions, isReview, id })
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   if (data.status == "success") {
    //     if (hasAdditional) {
    //       if (data.member) {
    //         if (searchParams.get("member") === data.memberId) {
    //           navigate("/assessment-additional/" + data.id + "?" + searchParams, { replace: true });
    //         } else navigate("/assessment-additional/" + data.id + "?member=" + data.memberId, { replace: true });
    //       } else navigate("/assessment-additional/" + data.id, { replace: true });
    //     } else {
    //       if (searchParams.get("user") === data.memberId) {
    //         navigate("/details/" + data.id + "?member=" + data.memberId, { replace: true });
    //       } else {
    //         // navigate("/pre-additional-info/" + data.id, { replace: true });
    //         navigate("/details/" + data.id + "?" + searchParams, { replace: true });
    //       }
    //     }
    //   } else {
    //     // setOpenSnackbar(true);
    //   }
    // });

  }

  console.log("searchParams", [...searchParams]);

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if (savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  // move to next question without waiting for submitAnswer API request to complete
  const goToNextQuestion = () => {
    // this should not depend on submitAnswer API response as that needs to happen in the background
    setTimeout(() => {
      if (activeStep < (questions?.length - 1)) {
        // setActiveStep(activeStep + 1);
        if (questionsWithoutStats.includes(questions?.[activeStep]?.orderNo)) {
          setShowStatistics(false);
          // setActiveStep((prev) => prev + 1);
          setActiveStep(activeStep + 1);
        } else if (showStatistics) {
          setShowStatistics(false);
          setActiveStep(activeStep + 1);
        } else {
          setShowStatistics(true);

        }
      } else {
        completeAssessment();
      }
    }, 500);
  }

  const prevQuestion = () => {
    setShowOtherOption(false)
    if (showStatistics) {
      // setPrevBtnClicked(false);
    } else setPrevBtnClicked(true);
    // if(activeStep < 1) {
    //   // if(searchParams.get("member")) {
    //   if([...searchParams]?.length) {
    //     // navigate("/bookkeeping/start?member=" + searchParams.get("member"), { replace: true });
    //     navigate("/bookkeeping/start?" + searchParams, { replace: true });
    //   } else navigate("/bookkeeping/start", { replace: true });
    // } else {
    //   setActiveStep(activeStep - 1);
    // }

    if (activeStep > 0) {
      if (showStatistics) {
        // setShowStatistics(false);
        setActiveStep(activeStep - 1);
      } else if (questionsWithoutStats.includes(questions?.[activeStep - 1]?.orderNo)) {
        // setShowStatistics(false);
        setActiveStep(activeStep - 1);
        let newActiveStep = activeStep - 1;
        // loop back until a question without skip = true is found 
        while (newActiveStep >= 0 && questions?.[newActiveStep]?.skip) {
          newActiveStep -= 1;
        }
        setActiveStep(newActiveStep);
      } else {
        // setShowStatistics(true);
        setActiveStep(activeStep - 1);
      }
    } else if (activeStep == 0 && showStatistics) {
      setShowStatistics(false);
    } else {
      navigateBackwardFunc();
    }
  };

  const nextQuestion = () => {

    setShowOtherOption(true)
    assessCtx.updateAnswer(questions?.[activeStep].id, questions?.[activeStep].answer);
    assessCtx.updateTextAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt);
    // assessCtx.updateOtherAnswerValue(questions?.[activeStep].id, questions?.[activeStep]?.isOtherValue);
    // assessCtx.updateOtherAnswerValue(questions?.[activeStep].id, questions?.[activeStep]?.isOther);
    if (questionType === "checkbox") {
      assessCtx.updateCheckboxAnswer(questions?.[activeStep].id, questions?.[activeStep].answers);
      submitAnswer(0); // does not matter what we pass as answer arg as answers array is used for checkbox
    }
    if (questionType === "select") {
      if (questions?.[activeStep]?.answerTxt?.length > 0 && questions?.[activeStep]?.answer === 2) {
        // console.log("submitAnswer answer--",questions?.[activeStep])
        submitAnswer({ value: 2, optionId: null });
        return;
      }
    }


    // else if (hasAdditional) {
    //   // if(searchParams.get("member") != null) {
    //     if (searchParams?.get("member")) {
    //       navigate("/assessment-additional/" + currentHash + "?" + searchParams, { replace: true });
    //     } else {
    //       navigate("/pre-additional-info/" + currentHash + "?" + searchParams, { replace: true });
    //     }
    // } else {
    //   // if(searchParams.get("member") != null) {
    //   if(searchParams.get("member") && currentHash) {
    //     navigate("/details/" + currentHash + "?" + searchParams, { replace: true });
    //   } else {
    //     console.log("\nSUBMITTING ANSWERS\n");
    //     setTimeout(() => { completeAssessment(); }, 500);
    //   }
    // }


    // if (activeStep < questions?.length - 1) {
    //   if (questions?.[activeStep]?.answer > 0 || questions?.[activeStep]?.skip || questions?.[activeStep]?.answerTxt?.length > 0) {
    //     setActiveStep((prev) => prev + 1);
    //   }
    // } else {
    //   // navigateForwardFunc();
    //   // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
    //   if (questions[activeStep]?.answer > 0) {
    //     // navigate(`/assessment-additional/${id}/${addQPageId}?${searchParams}`, { replace: true });
    //     // navigate(`/details/${id}?${searchParams}`, { replace: true });
    //     setTimeout(() => { completeAssessment(); }, 500);
    //   }
    // }

    if (activeStep < (questions?.length - 1) && !(onLastStatisticsPage && showStatistics)) {
      if (questions?.[activeStep]?.answer > 0 || questions?.[activeStep]?.skip || questions?.[activeStep]?.answerTxt?.length > 0) {
        // setActiveStep((prev) => prev + 1);
        if (showStatistics && !onLastStatisticsPage) {
          setActiveStep((prev) => prev + 1);
          setShowStatistics(false);
        } else if (questionsWithoutStats.includes(questions?.[activeStep]?.orderNo)) {
          setActiveStep((prev) => prev + 1);
          setShowStatistics(false);
        }
        else {
          setShowStatistics(true);
        }
      }
    } else {
      // navigateForwardFunc();
      // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
      if (questions[activeStep]?.answer >= 0) {
        // navigate(`/assessment-additional/${id}/${addQPageId}?${searchParams}`, { replace: true });
        // navigate(`/details/${id}?${searchParams}`, { replace: true });
        setTimeout(() => { completeAssessment(); }, 500);
      }
    }

  };


  const saveAnswerSubmissionStatus = (qId, submissionSuccess) => {
    setQuestions((prev) => prev.map((q) => {
      if (q.id === qId) {
        q.submissionSuccess = submissionSuccess;
      }
      return q;
    }));
  }

  const getAnswer = (answer, ansTxt) => {
    // console.log("answer--",answer)
    if (ansTxt === "None") return 1;
    return answer.value > 0 ? answer.value : 0
  }

  const submitAnswer = (answer) => {
    // console.log("submitAnswer answer--",answer)
    let jsonBody = {
      // "id": questions?.[activeStep]?.id,
      "pageId": pageId,
      // "answer": answer.value > 0 ? 1 : 0,
      "answer": getAnswer(answer, questions?.[activeStep]?.answerTxt),
      "answerTxt": questions?.[activeStep]?.answerTxt,
      "answerChecked": false,
      "optionId": answer.id,
    }


    if (answer?.label?.toLowerCase() == 'none') {
      jsonBody = {
        // "id": questions?.[activeStep]?.id,
        "pageId": pageId,
        // "answer": answer.value > 0 ? 1 : 0,
        "answer": getAnswer(answer, questions?.[activeStep]?.answerTxt),
        "answerTxt": questions?.[activeStep]?.answerTxt,
        "answerChecked": false,
      }
    }


    if (questionType === "checkbox") {
      jsonBody = {
        // "id": questions?.[activeStep]?.id,
        "pageId": pageId,
        "answer": answer,
        "answers": questions?.[activeStep]?.answers,
        "answerTxt": null,
        "answerChecked": true,
      }
    }
    console.log("jsonBody", jsonBody);

    setIsSubmittingAnswer(true);
    setAnswerSubmissionError(false);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/question/' + questions?.[activeStep]?.id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      // // this should not depend on API response as it needs to happen in the background
      // if (activeStep < (questions?.length - 1)) {
      //   setActiveStep(activeStep + 1);
      // } else {
      //   // navigate(navigatePath, { replace: true });
      //   // navigateForwardFunc();
      //   completeAssessment();
      // }

      if (data.status === "success") {
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        // save the answer option so you see the selected option when going back & forth between questions 
        setIsSubmittingAnswer(false);
        setAnswerSubmissionError(false);
        // saveAnswerSubmissionStatus(questions?.[activeStep]?.id, true);

        console.log("data", data);
      } else {
        // setIsSaving(false);
        console.error("Failed to send answer details...", data);
        setIsSubmittingAnswer(false);
        setAnswerSubmissionError(true);
        // saveAnswerSubmissionStatus(questions?.[activeStep]?.id, false);
      }
    }).catch((err) => {
      console.error("Failed to send answer details...", err);
      setIsSubmittingAnswer(false);
      setAnswerSubmissionError(true);
      // saveAnswerSubmissionStatus(questions?.[activeStep]?.id, false);
    });

    // goToNextQuestion should not depend on submitAnswer API response as that needs to happen in the background
    goToNextQuestion();

  }

  const getBenchMarkingAnswers = () => {

    fetch(process.env.REACT_APP_API_URI + '/v4/user/assessment/missio/benchmarks', {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setCurrentStatisticData(data?.data)
      }

    }).catch((err) => {
      console.error("Failed to get benchmarking details...", err);
    })

  }

  const navigatePath = (pgId) => "/assessment/" + id + "/" + pgId + "/?" + searchParams;
  const navigateForwardFunc = () => {
    if (pageId === "28") {
      navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
    } else if (pageId === "30") {
      navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
    } else if (pageId === "32") {
      navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
    } else if (pageId === "34") {
      navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
    } else if (pageId === "36") {
      navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
    } else if (pageId === "804") {
      navigate("/assessment-additional/" + id + "/805?" + searchParams, { replace: true });
    }
  }
  const navigateBackwardFunc = () => {
    // let qOrderNum = questions?.[activeStep]?.orderNo;
    let qOrderNum = activeStep + 1;
    console.log("qOrderNum", qOrderNum);
    console.log("pageId", pageId, typeof pageId);

    if (pageId === "28") navigate("/first-form/" + id + "?" + searchParams, { replace: true });
    else if (pageId === "30") {
      if (qOrderNum == 2) navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(28), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "32") {
      if (qOrderNum == 5) navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(30), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "34") {
      if (qOrderNum == 8) navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(32), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "36") {
      if (qOrderNum == 11) navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(34), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "38") {
      if (qOrderNum == 14) navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(36), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    } else {
      navigate("/explainer/" + id + searchParams, { replace: true });
    }
  }

  const moveToNextQuestion = (answer) => {
    // questions[activeStep].answer = answer;
    console.log("answer", answer);
    setQuestions((prev) => prev.map((q) => {
      if (q.id === questions?.[activeStep]?.id) {
        // q.answer = answer.value > 0 ? 1 : 0;
        q.answer = getAnswer(answer, questions?.[activeStep]?.answerTxt);
        q.answerTxt = answer?.label
        q.optionId = answer.id;
        if (q.na === "yes" && answer > 0) {
          q.skip = false;
        }
      }
      return q;
    }));
    assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
    assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);
    // if(activeStep < questions?.length - 1) {
    //   setTimeout(() => {
    //     setActiveStep(activeStep + 1);
    //   }, 500);
    // } else {
    //   // questions?.[activeStep].answer = answer;
    //   console.log("\n\nSUBMITTING ANSWERS\n\n");
    //   setTimeout(() => { completeAssessment(); }, 500);
    // }

    // setTimeout(() => {
    submitAnswer(answer);
    // }, 500);
    console.log("answer", answer);

  }

  const updateMultiSelectQuestion = (answer) => {
    setQuestions((prev) => prev.map((q) => {
      if (q.id === questions?.[activeStep]?.id) {
        // q.answer = answer.value > 0 ? 1 : 0;
        q.answer = getAnswer(answer, questions?.[activeStep]?.answerTxt);
        q.answerTxt = answer?.label
        q.optionId = answer.id;
        if (q.na === "yes" && answer > 0) {
          q.skip = false;
        }
      }
      return q;
    }));
  }

  const nextDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return { display: "flex" };
    }
    if (questions?.[activeStep]?.answer > 0) {
      return { display: "flex" };
    };
    return { display: "flex" };
  }

  console.log("queAns", questions?.[activeStep])
  console.log("questions?.[activeStep]?.answerTxt?.length", questions?.[activeStep]?.answerTxt?.length)

  const checkNextBtnDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return false;
    }
    if (questions?.[activeStep]?.answerType === "checkbox") {
      const anyOptionChecked = questions?.[activeStep]?.answers?.some(option => option.checked);
      return !anyOptionChecked;
    }
    if (
      questions?.[activeStep]?.answer > 0 ||
      questions?.[activeStep]?.skip ||
      questions?.[activeStep]?.answerTxt?.length > 0
    ) {
      return false;
    }
    if (showStatistics) return false;
    return true;
  };

  // const nextDisabled = (answer) => {
  //   if(questions?.[activeStep]?.mandatory === "no") {
  //     return {display: "block"};
  //   } else {
  //     // if(questions?.[activeStep]?.answerType == "slider" && questions?.[activeStep]?.answer != 0) {
  //     if(questions?.[activeStep]?.answerType == "slider" && !questions?.[activeStep]?.answer > 0) {
  //       return { display: "block" };
  //     } else if(questions?.[activeStep]?.answerType == "radio" && questions?.[activeStep]?.answer != 0) {
  //       return {display: "block"};
  //     } else if(questions?.[activeStep]?.answerType == "textarea" && questions?.[activeStep]?.answerTxt != "") {
  //       return {display: "block"};
  //     }
  //   } 
  //   return {display: "none"};
  // }

  const showHideNextBtn = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return true;
    }
    if (questions?.[activeStep]?.answerType === "checkbox") {
      const anyOptionChecked = questions?.[activeStep]?.answers?.some(option => option.checked);
      if (anyOptionChecked) return true;
      else return false;
    }
    if (questions?.[activeStep]?.answer > 0 || questions?.[activeStep]?.skip || questions?.[activeStep]?.answerTxt?.length > 0) {
      return true;
    };
    if (showStatistics) return true;
    return false;
  }

  const updateTextAnswer = (id, textVal, answer) => {
    console.log(textVal)
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        // for missio nexus, if answer is Other, user has to type in answer which is answerTxt, and its score should be 2
        q.answerTxt = textVal;
        q.answer = answer;
      }
      return q;
    }))
    // submitAnswer(0);
    // submitAnswer({ value: 1, optionId: null });
  }

  const updateIsOtherOption = (id, isOther) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        // q.isOther = isOther;
      }
      return q;
    }))
    // assessCtx.updateOtherAnswer(questions?.[activeStep].id, questions?.[activeStep]?.isOther);
  }


  const updateOtherAnswerValue = (id, value) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        // q.isOtherValue = value;
        // q.answer = 0;
      }
      return q;
    }))
    // assessCtx.updateOtherAnswerValue(questions?.[activeStep].id, questions?.[activeStep]?.isOtherValue);

  }



  const updateCheckboxQuestion = (id, checkboxAnswers) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        q.answers = checkboxAnswers;
      }
      return q;
    }));
  }

  const answerAsNotApplicable = (id, checked) => {
    // checked is boolean. if checked, skip will be set to true
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        q.skip = checked;
        if (checked) {
          q.answer = 1;
          q.answerTxt = "not applicable";
        }
      }
      return q;
    }));
    if (checked) {
      // setTimeout(() => {
      submitAnswer(0);
      // }, 500);
    }
  }
  const answerSkip = (id) => {
    setShowOtherOption(true)
    setQuestions(questions?.map((q) => {
      if (q.id === questions?.[activeStep]?.id) {
        q.skip = true;
        q.answer = 0;
        q.answerTxt = "";
        // q.isOther = false;
        q.isOtherValue = {};
      }
      return q;
    }));
    submitAnswer(0);
  }

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr?.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }

  const questionsPerSection = getQuestionsPerSection();
  // const questionsPerSection = [4, 5, 4, 3];
  console.log("questionsPerSection", questionsPerSection);
  console.log("\n\nquestions?.[activeStep]", activeStep, (questions?.length - 1), "\n\n");
  console.log("assessCtx.questions", assessCtx.questions);
  console.log("questions", questions);
  console.log("CurrentStatisticData", currentStatisticData)
  console.log("questions steps", questions?.[activeStep])


  const getStepContent = (step) => {
    console.log("questions steps", questions?.[step])
    console.log("steps", step)
    console.log("steps", step)
    return (
      <Question question={questions?.[step]} questionCount={questions?.length} updateMultiSelectQuestion={updateMultiSelectQuestion} questionAnswered={moveToNextQuestion} updateTextAnswer={updateTextAnswer}
        // updateIsOtherOption={updateIsOtherOption} updateOtherAnswerValue={updateOtherAnswerValue} 
        updateCheckboxQuestion={updateCheckboxQuestion} answerAsNotApplicable={answerAsNotApplicable} showOtherOption={showOtherOption} />
    );
  };
  const  queriesAndStatements = [
    {
        query: "Which tool/service do you MOST use for video production?",
        statement: ["of surveyed ministries also use","for their video production needs."]
    },
    {
        query: "Which tool/service do you MOST use for podcasting?",
        statement:[` of surveyed ministries also use `,` for their podcasting needs.`]
    },
    {
        query: "Which tool/service do you MOST use for managing and distributing email communications?",
        statement: [`of surveyed ministries also use `,`  for managing email communications.`]
    },
    {
        query: "Which tool/service do you MOST use for direct mailing?",
        statement: [`of surveyed ministries also use `,` for their direct mailing needs.`]
    },
    {
        query: "Which conference do you MOST prefer to attend for development/networking?",
        statement: [`of surveyed ministries also prefer to attend the `, ` for development/networking.`]
    },
    {
        query: "Which association/denomination/network are you MOST plugged in with?",
        statement: [`of surveyed ministries are also plugged into the`]
    },
    {
        query: "Which health insurance plan do you use?",
        statement: [`of surveyed ministries also use `,` for their health insurance plan.`]
    },
    {
        query: "Which health insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` as their health insurance provider.`]
    },
    {
        query: "Which tool/service do you MOST use for employee benefit service management?",
        statement: [`of surveyed ministries also use `,` for employee benefit service management.`]
    },
    {
        query: "Which tool/service do you MOST use for employee resource planning (human resources)?",
        statement: [`of surveyed ministries also use `,` for employee resource planning.`]
    },
    {
        query: "Which tool/service do you MOST use for auditing?",
        statement: [`of surveyed ministries also use `,` for their auditing needs.`]
    },
    {
        query: "Which tool/service do you MOST use for accounting?",
        statement: [`of surveyed ministries also use `,` for their accounting needs.`]
    },
    {
        query: "Which tool/service do you MOST use for expense report generation?",
        statement: [`of surveyed ministries also use `,` for expense report generation.`]
    },
    {
        query: "Which tool/service do you MOST use for payroll management?",
        statement: [`of surveyed ministries also use `,` for their payroll management.`]
    },
    {
        query: "Which tool/service do you MOST use for international money transfers?",
        statement: [`of surveyed ministries also use `,` for international money transfers.`]
    },
    {
        query: "Which tool/service do you MOST use for fundraising?",
        statement: [`of surveyed ministries also use `,` for their fundraising needs.`]
    },
    {
        query: "Which web-based CRM do you MOST use for giving?",
        statement: [`of surveyed ministries also use `,` to manage their giving.`]
    },
    {
        query: "Which tool/service do you MOST use as your primary digital workspace?",
        statement: [`of surveyed ministries also use `,` as their primary digital workspace.`]
    },
    {
        query: "Which website platform do you MOST use?",
        statement: [`of surveyed ministries also use `,` as their website platform.`]
    },
    {
        query: "Which tool/service do you MOST use for Information Technology (IT) support (outsourced, insourced)?",
        statement: [`of surveyed ministries also use `,` for their IT support needs.`]
    },
    {
        query: "Which liability insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` for their liability insurance.`]
    },
    {
        query: "Which Directors & Officers (D&O) liability insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` for their D&O liability insurance.`]
    },
    {
        query: "Which workman’s compensation insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` for workman’s compensation insurance.`]
    },
    {
        query: "Which domestic property insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` for domestic property insurance.`]
    },
    {
        query: "Which foreign property insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` for foreign property insurance.`]
    },
    {
        query: "Which travel insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` for travel insurance.`]
    },
    {
        query: "Which kidnapping and ransom insurance provider do you use?",
        statement: [`of surveyed ministries also use `,` for kidnapping and ransom insurance.`]
    },
    {
        query: "Which CRM do you MOST use for mobilization?",
        statement: [`of surveyed ministries also use `,` for their mobilization needs.`]
    },
    {
        query: "Which tool/service do you MOST use for short-term ministry management?",
        statement: [`of surveyed ministries also use `,` for their short-term ministry management.`]
    },
    {
        query: "Which tool/service do you MOST use for training, mid-term, long-term sending?",
        statement: [`of surveyed ministries also use `,` for their training needs.`]
    },
    {
        query: "Which recruitment conference do you MOST OFTEN frequent?",
        statement: [`of surveyed ministries also often attend `,` for recruitment`]
    },
    {
        query: "Which counseling service (Inhouse, outsourced) do you MOST use?",
        statement: [`of surveyed ministries also use `,` for their counseling needs.`]
    },
    {
        query: "Which assessment tools and services do you MOST use?",
        statement: [`of surveyed ministries also use `,` as their main assessment tool/service.`]
    },
    {
        query: "Which tool/service do you MOST use for travel management?",
        statement: [`of surveyed ministries also use `,` for their travel management.`]
    }
];

const  otherBenchmarks = [
  {
      query: "Which tool/service do you MOST use for video production?",
      statement: ["Thank you for bringing","  to our attention for video production needs. We appreciate your input!"]
  },
  {
      query: "Which tool/service do you MOST use for podcasting?",
      statement:[` Thank you for bringing `,`  to our attention for podcasting needs. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for managing and distributing email communications?",
      statement: [`Thank you for bringing `,` to our attention for email communications. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for direct mailing?",
      statement: [`Thank you for bringing `,` to our attention for direct mailing needs. We appreciate your input!`]
  },
  {
      query: "Which conference do you MOST prefer to attend for development/networking?",
      statement: [`Thank you for bringing to attend the `, ` to our attention for development/networking. We appreciate your input!`]
  },
  {
      query: "Which association/denomination/network are you MOST plugged in with?",
      statement: [`Thank you for bringing `, ` to our attention. We appreciate your input!`]
  },
  {
      query: "Which health insurance plan do you use?",
      statement: [`Thank you for bringing `,` to our attention as a health insurance plan. We appreciate your input!`]
  },
  {
      query: "Which health insurance provider do you use?",
      statement: [`Thank you for bringing `,`  to our attention as a health insurance provider. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for employee benefit service management?",
      statement: [`Thank you for bringing `,` to our attention for employee benefit service management. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for employee resource planning (human resources)?",
      statement: [`Thank you for bringing `,` to our attention for employee resource planning. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for auditing?",
      statement: [`Thank you for bringing `,` to our attention for auditing needs. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for accounting?",
      statement: [`Thank you for bringing `,` to our attention for accounting needs. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for expense report generation?",
      statement: [`Thank you for bringing `,`  to our attention for expense report generation. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for payroll management?",
      statement: [`Thank you for bringing `,` to our attention for payroll management. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for international money transfers?",
      statement: [`Thank you for bringing `,` to our attention for international money transfers. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for fundraising?",
      statement: [`Thank you for bringing `,`  to our attention for fundraising needs. We appreciate your input!`]
  },
  {
      query: "Which web-based CRM do you MOST use for giving?",
      statement: [`Thank you for bringing `,` to our attention to manage giving. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use as your primary digital workspace?",
      statement: [`Thank you for bringing `,`  to our attention as a digital workspace. We appreciate your input!`]
  },
  {
      query: "Which website platform do you MOST use?",
      statement: [`Thank you for bringing `,` to our attention as a website platform. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for Information Technology (IT) support (outsourced, insourced)?",
      statement: [`Thank you for bringing `,` to our attention for IT support. We appreciate your input!`]
  },
  {
      query: "Which liability insurance provider do you use?",
      statement: [`Thank you for bringing `,` to our attention as a liability insurance provider. We appreciate your input!`]
  },
  {
      query: "Which Directors & Officers (D&O) liability insurance provider do you use?",
      statement: [`Thank you for bringing `,`  to our attention as a D&O liability insurance provider. We appreciate your input!`]
  },
  {
      query: "Which workman’s compensation insurance provider do you use?",
      statement: [`Thank you for bringing `,` to our attention as a workman’s compensation insurance provider. We appreciate your input!`]
  },
  {
      query: "Which domestic property insurance provider do you use?",
      statement: [`Thank you for bringing `,` to our attention as a domestic property insurance provider. We appreciate your input!`]
  },
  {
      query: "Which foreign property insurance provider do you use?",
      statement: [`Thank you for bringing `,` to our attention as a foreign property insurance provider. We appreciate your input!`]
  },
  {
      query: "Which travel insurance provider do you use?",
      statement: [`Thank you for bringing `,` to our attention as a travel insurance provider. We appreciate your input!`]
  },
  {
      query: "Which kidnapping and ransom insurance provider do you use?",
      statement: [`Thank you for bringing `,`  to our attention as a kidnapping and ransom insurance provider. We appreciate your input!`]
  },
  {
      query: "Which CRM do you MOST use for mobilization?",
      statement: [`Thank you for bringing `,` to our attention for mobilization needs. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for short-term ministry management?",
      statement: [`Thank you for bringing `,` to our attention for short-term ministry management. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for training, mid-term, long-term sending?",
      statement: [`Thank you for bringing `,`  to our attention for training needs. We appreciate your input!`]
  },
  {
      query: "Which recruitment conference do you MOST OFTEN frequent?",
      statement: [`Thank you for bringing attend `,` to our attention for recruitment. We appreciate your input!`]
  },
  {
      query: "Which counseling service (Inhouse, outsourced) do you MOST use?",
      statement: [`Thank you for bringing `,` to our attention for counseling needs. We appreciate your input!`]
  },
  {
      query: "Which assessment tools and services do you MOST use?",
      statement: [`Thank you for bringing `,` to our attention for assessment needs. We appreciate your input!`]
  },
  {
      query: "Which tool/service do you MOST use for travel management?",
      statement: [`Thank you for bringing `,` to our attention for travel management. We appreciate your input!`]
  }
];


  const getStatistics = (step) => {

    console.log(" priyanshusteps", step)
    const filteredAnswers = questions?.[activeStep]?.answers?.filter(answer => answer !== 'Skip' && answer !== 'None' && answer.desktopText !== 'Skip' && answer.desktopText !== 'None');
    const benchmarkCurrentData = queriesAndStatements.filter(data => data?.query === questions?.[activeStep]?.question);
    const otherBenchmarkingStatement = otherBenchmarks.filter(data => data?.query === questions?.[activeStep]?.question)
    const currentanswerTxt = questions?.[activeStep]?.answerTxt;
    const currentStat = questions?.find(stat => stat.id == activeStep);
    const currentStats = [];
    currentStatisticData?.forEach(stat => {
      if (stat.questionId == questions?.[activeStep]?.id && stat.answer != -1) {
        currentStats.push(stat);
      }
    });

    // console.log("filteredAnswers", filteredAnswers)
    console.log("currentStats",questions?.[activeStep],currentanswerTxt)


    currentStats.sort((a, b) => Math.round(b.totalPercentage) - Math.round(a.totalPercentage))

    // console.log("currentStats: ",currentStats)

    const barColors = ['#284F88', '#0061B1', '#5A9FD8', '#6EB3EC', '#8CD1FF', '#9EDFFF', '#B1E5FF', '#C3ECFF', '#D6F2FF', '#D7EBF9', '#D9D9D9', '#b9b9b9', '#a8a8a8']

    // const currentStat = currentStatisticData?.find(stat => stat.questionId == questions?.[activeStep]?.questionId);

    // const skipQuestionNumber = 22;

    function skipStatistic() {
      setShowStatistics(false);
    }


    // if (currentStat.id === skipQuestionNumber) {
    //   skipStatistic();
    // }
    

    return (
      <div className="statistics">
        <div className="score-section-container">
          {filteredAnswers
            ?.map((data, index) => {
              const matchedStat = currentStats.find(stat => stat.answerText === data.desktopText);
              const percentage = matchedStat ? Math.round(matchedStat?.totalPercentage) : 0;
              return {
                data,
                percentage,
              };
            })
            .sort((a, b) => b.percentage - a.percentage)
            .map(({ data, percentage }, index) => {
              const matchedStat = currentStats.find(stat => stat.answerText === data.desktopText);
              const isSelected = data.desktopText === currentanswerTxt;    
              return (
                <div className="rowdata" key={index}>
                  <p className="colnname" style={{ fontWeight: isSelected ? 900 : 'normal' }}>
                    {data.desktopText}
                  </p>
                  <div className="colscore">
                    <div style={{ width: `${Math.max(percentage, 0.5)}%`, background: '#5A9FD8' }}></div>
                    <h3>{percentage}%</h3>
                  </div>
                </div>
              );
            })}
        </div>
        <p className="chart-title">
        {filteredAnswers.some(answer => answer.desktopText === currentanswerTxt) ? (

          <p>
            <b>
              {
                Math.round(
                  currentStats.find( stat => stat.answerText === currentanswerTxt)
                ?.totalPercentage || 0)
              }
              % {" "}
            </b>
            {benchmarkCurrentData?.[0]?.statement[0]} <b>{currentanswerTxt}</b>{' '}
            {benchmarkCurrentData?.[0]?.statement[1] || ''}
          </p>
      ) : (
        <span>
        {otherBenchmarkingStatement?.[0]?.statement[0]} <b>{currentanswerTxt}</b>{' '}
        {otherBenchmarkingStatement?.[0]?.statement[1] || ''}
      </span>
      )}
        </p>
      </div>
    );
  }

  if (isLoading) {
    return <div className="container assessment loading"><CircularProgress style={{ color: '004F88' }} size={60} /></div>
  }

  const currentanswerTxt = questions?.[activeStep]?.answerTxt;

  return (
    <>

      <div className="container assessment">
        {/* {windowWidth < 830 ? <> */}
        {/* <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={questionCount} /> */}
        <ProgressBarSingle currentProgress={parseInt(activeStep)} totalNumOfQuestions={questions?.length} />
        <p className="current-section">{currentSectionName}</p>
        {/* </> : 
        <div className="section-progress-top">
          <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
        </div>} */}
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}></Stepper>
        </Box>
        <div>
          {(!showStatistics) ? <div className="steps-container">
            <div>
              <div className="steps">

                {/* {(windowWidth > 830 && questionType !== "checkbox") && <div className="spacer"></div>} */}

                {/* {windowWidth > 830 ?
                <>
                  <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
                  <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={16} />
                </>
                : <>
                  <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} />
                  <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={16} />
                  <p className="current-section">{currentSectionName}</p>
                </>
              } */}
                {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} sections={sections} currentSectionName={currentSectionName} /> */}
                {/* <div className="spacer"></div> */}
                {/* <div className="spacer"></div> */}
                {/* <div className="spacer mobile-spacer"></div> */}
                {/* <div className="spacer mobile-spacer"></div> */}

                {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/16</div> */}
                <div className="progress-indicator">
                  {/* <p className="step-no">{questions?.[activeStep]?.orderNo}</p> */}
                  <p className="step-no">{activeStep + 1}</p>
                  /
                  <p className="total-question-count">{questions?.length}</p>
                </div>

                {getStepContent(activeStep)}
                {/* <QuestionInfo /> */}

              </div>
            </div>
          </div> : <>
            {/* {currentanswerTxt !== "None" ? (
              isSubmittingAnswer ? ( <div className="loading-container"><CircularProgress style={{ color: '#004F88' }} size={60} /></div>) : (getStatistics(activeStep))
            ) : (
              isSubmittingAnswer ? (<div className="loading-container"><CircularProgress style={{ color: '#004F88' }} size={60} /></div>) : (nextQuestion())
            )} */}
          {isSubmittingAnswer ? <div className="loading-container"><CircularProgress style={{ color: '#004F88' }} size={60} /></div> : nextQuestion()}
          </>}

          <div className="step-buttons" style={{ justifyContent: "space-between" }}>
            <Button onClick={prevQuestion} className="prev-btn" style={{ minWidth: showNextBtn && "119px" }}>
              <img src={prevArrow} alt="previous button" />
              {/* {windowWidth <= 550 ? (
                <><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;</>
              ) : (
                <><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;</>
              )} */}
            </Button>
            {
              !showStatistics && <Button className='skip' onClick={() => { answerSkip() }}>
                Skip
              </Button>
            }

            { /* showHideNextBtn() */ (showNextBtn && !isLoading ) ? <Button className="next-btn" onClick={nextQuestion} disabled={!isNextBtnEnabled}

            // style={nextDisabled()}
            >
              Next
              {/* {windowWidth <= 550 ? (
                <span style={{ fontSize: "18px", }}>NEXT</span>
              ) : (
                <><span style={{ fontSize: "18px", }}>NEXT</span><img style={{ paddingLeft: "8px" }} src={nextArrowWhite} alt="next button" /></>
              )} */}
            </Button> : <div style={{ height: '50px', width: '50px' }}></div>}
          </div>

        </div>
      </div>

      {(showStatistics && !answerSubmissionError) && <div className='showstatistics'>
        <p className='note'><span>Note: </span>This tool is in beta and will improve as we gather more results. We’ll send an updated report via email once we have sufficient data.</p>
      </div>}

      {answerSubmissionError &&
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            Failed to submit answer. Please try again.
          </Alert>
        </Snackbar>
      }

      {/* TODO: uncomment this after confirming this error indication feature addition with client team */}
      {/* {questions?.[activeStep]?.submissionSuccess === false &&
        <Snackbar open={queSnackbarOpen} autoHideDuration={4000} onClose={handleCloseQueSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseQueSnackbar} severity="error">
            Error submitting answer for question {questions?.[activeStep]?.orderNo}. Please try again.
          </Alert>
        </Snackbar>
      } */}
    </>
  );
}

export default AssessmentV3;

