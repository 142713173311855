import { createContext, useState } from 'react';


const AdditionalQuestionContext = createContext({
  questions: {},
  addQuestions: (addQuestions) => { },
  updateAnswer: (updateAnswer) => { },
  updateTextAnswer: (updateTextAnswer) => { },
  updateOtherAnswer: (updateOtherAnswer)=> { },
  updateOtherAnswerValue:(updateOtherAnswerValue)=> { },
});

export function AdditionalQuestionContextProvider(props) {
  const [questions, setQuestions] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [form1Details, setForm1Details] = useState({ zipCode: "", avgWeeklyAttd: "" });
  const [bookkeepingSoftwareValues, setBookkeepingSoftwareValues] = useState([
    { label: "Aplos", value: "Aplos" },
    { label: "Wave", value: "Wave" },
    { label: "Acts", value: "Acts" },
    { label: "QuickBooks", value: "QuickBooks" },
    { label: "ChurchTrac", value: "ChurchTrac" },
    { label: "IconCMO", value: "IconCMO" },
    { label: "PowerChurch", value: "PowerChurch" },
    { label: "ShelbyNext Financials", value: "ShelbyNext Financials" },
    { label: "Realm", value: "Realm" },
    { label: "Xero", value: "Xero" },
    { label: "NetSuite", value: "NetSuite" },
    { label: "Zoho Books", value: "Zoho Books" },
    { label: "Quicken", value: "Quicken" },
    { label: "FreshBooks", value: "FreshBooks" },
  ]);

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function addFormDetailsHandler(data) {
    setFormDetails(data);
  }

  function addForm1DetailsHandler(data) {
    setForm1Details(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answer = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answerTxt = answer;
    let localQuestions = [];
    console.log("We are here", answer);
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  function updateOtherAnswerHandler(questionId, other) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.isOther = other;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }
  function updateOtherAnswerValueHandler(questionId, value) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.otherValue = value;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }

  const context = {
    questions: questions,
    formDetails: formDetails,
    form1Details: form1Details,
    addQuestions: addQuestionsHandler,
    addFormDetails: addFormDetailsHandler,
    addForm1Details: addForm1DetailsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler,
    bookkeepingSoftwareValues: bookkeepingSoftwareValues,
    setBookkeepingSoftwareValues: setBookkeepingSoftwareValues,
    updateOtherAnswer : updateOtherAnswerHandler,
    updateOtherAnswerValue: updateOtherAnswerValueHandler
  };

  return <AdditionalQuestionContext.Provider value={context}>
    {props.children}
  </AdditionalQuestionContext.Provider>
}

export default AdditionalQuestionContext;
